import { HOME_PAGE_GAMES_API, GAMES_FILTERS_API, PLACE_BET_API } from "../config";
import authService from './AuthClient';
const parseJSON = response => response.json();

const handleErrors = response => {
    if(response.ok) {
        return response;
    }
    return Promise.reject(response);
}

const apiCall = async (
    url,
    token,
    options = {
        header: { }
    }
 ) => {
    try {
        const response = await fetch(url, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`
            }
        });
        const json = await handleErrors(response);
        return parseJSON(json);
    }
    catch (error) {
        if (error.status !== 401) {
            return Promise.reject(error);
        }
    }
}

const getGames=(filter="Sports Bets", search="") => {
    return apiCall(HOME_PAGE_GAMES_API + "?filter=" + filter + "&search=" + search);
}

const getFilters=() => {
    return apiCall(GAMES_FILTERS_API);
}

const placeBet=(game) => {
    const options = {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            "gameName": game
        })
    }
    const token = authService.getToken();
    return apiCall(PLACE_BET_API, token, options);
}

export { getGames, getFilters, placeBet }