import React from "react";
import footerBLogoImage from '../assets/images/footer-b-logo.png';

const BottomFooter = () => (
    <div className="bottom-footer">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <a href="/"><img className="footer-logo1" src={ footerBLogoImage } alt="#" /></a>
          <p>Copyright &copy; {(new Date().getFullYear())} Betfinity All rights reserved.</p>
        </div>
      </div>
    </div>
  </div>
);

export default BottomFooter;
