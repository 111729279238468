import React, { Component } from "react";
import { Row, Col, Container } from "reactstrap";
import { getFilters, getGames } from "../utils/WebApiUtils"
import { chunk } from "lodash";
import Loading from "../components/Loading";

class GamesPanel extends Component {
    constructor(props) {
        super(props);
        this.state = { 
          isFetching: true,
          filters: [],
          games: [],
          activeFilter: null
        };
      }
    componentDidMount() {
        getFilters().then((result) => {
        this.setState({
            isFetching: false,
            filters: result,
            activeFilter: result[0]
            });
        });
        getGames("All Games").then((result) => {
          this.setState({
            isFetching: false,
            games: result
          });
        });
    };

    handleClick(id, filter, e) {
      e.preventDefault();
      this.setState({ activeFilter: filter, isFetching: true });
      getGames(filter.name).then((result) => {
        this.setState({
          isFetching: false,
          games: result
        });
      });
    };
    search() {
      getGames("All Games", this.searchInput.value).then((result) => {
        this.setState({
          isFetching: false,
          games: result
        });
      });
    };
    render () {
        const { isFetching, filters, activeFilter, games} = this.state;
        const cols = 4;
        const rows = chunk(games, cols);
    if (isFetching) {
      return <Loading />;
    }
    return (
    <div>
        <div className="search-sec">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <ul>
                  {filters.map((filter, i) => (
                    <li key={i} className={this.state.activeFilter.name === filter.name ? "active" : ""} onClick={(e) => this.handleClick(i, filter, e)}>
                      <div className="d-flex align-items-center justify-content-center">
                        <img src={filter.icon} alt={filter.alt} />
                      </div>
                      <p>{filter.name}</p>
                    </li>
                  ))}
                </ul>
                <div className="search-input">
                    <div className="search-input-col">
                        <input type="text" name="search" ref={ref => this.searchInput = ref} onKeyDown={(e) => (e.key === 'Enter') ? this.search(): false} />
                        <img src="/img/search.png" alt="Search" onClick={(e)=>this.search()} />
                    </div>
                    <p>Search for a game</p>
                </div>
              </div>
            </div>
          </div>

        </div>
          <Container>
          {
            activeFilter == null ? " " :
            <div className="container hidden-xs">
              <div className="row">
                <div className="col-12">
                <h4>
                  <img src={activeFilter.icon} alt={activeFilter.alt} />{activeFilter.name}
                </h4>
              </div>
              </div>
            </div>
          }
          </Container>
        <Container className="all-games-sec">
          {rows.map((cols, i) => (
            <Row key={i} className="games-row">
              {cols.map((game, ii) => (
                <Col key={ii} className={ "col-6 col-md-3 pt-md-6" }>
                  <a href={game.link} >
                  <div className={game.gameClass}>
                    <div className="all-games-col-inner" >
                      <img className="game-image" src={game.gameImage} alt={game.alt}/>
                      <img className="dots-img" src={game.dotsImage} alt={game.alt}/>
                    </div>
                    <div className="all-games-col-inner1">
                      { (game.showJackpotImage) ? <img src="/img/jackpot.png" alt="jackpot"/> : "" }
                      <p>{game.gameType}</p>
                    </div>
                  </div>
                  </a>
                </Col>
                ))}
              </Row>
            ))}
        </Container>
        </div>
)};
}

export default GamesPanel;