import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import { Container } from "reactstrap";

import PrivateRoute from "./components/PrivateRoute";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import ContentSec from "./components/ContentSec";
import Footer from "./components/Footer";
import BottomFooter from "./components/BottomFooter";
import Home from "./views/Home";
import GameDashboard from "./views/GameDashboard";
import Profile from "./views/Profile";
import { useAuth0 } from "./react-auth0-spa";
import history from "./utils/history";

// styles
import "./App.css";
import "../node_modules/react-input-range/lib/css/index.css";
import "./common.css";
import "./responsive.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { loading } = useAuth0();

  if (loading) {
    return <Loading />;
  }

  return (
    <Router history={history}>
      <NavBar className="navbar navbar-expand-lg navbar-light" />
      
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/dashboard" exact component={GameDashboard} />
          <PrivateRoute path="/profile" component={Profile} />
        </Switch>
      <Container >
      </Container>
      
      <Container>
      </Container>
      <Footer />
      <BottomFooter />
    </Router>
  );
};

export default App;
