import React from "react";

const Loading = () => (
  <div className="spinner">
    <figure>
        <div className="dot white"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
    </figure>
  </div>
);

export default Loading;
