import React from "react";
import DicePanel from "../Dice/DicePanel";
import RiskDuelPanel from "../RiskDuel/RiskDuelPanel";
import * as Games from '../Game/Games';

const gameList = [
    {
        name: Games.DiceGameName,
        onCreateGame: (gameArea) => (<DicePanel area={gameArea}></DicePanel>)
    },
    {
        name: Games.RiskDuelName,
        onCreateGame: (gameArea) => (<RiskDuelPanel area={gameArea}></RiskDuelPanel>)
    }
]

class GameAreaHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            game: null
        }
        this.onValueChange = this.onValueChange.bind(this);
    }

    componentDidMount() {
        var defaultGame = null;
        if (this.props.defaultGame) {
            defaultGame = gameList.find(t => t.name === this.props.defaultGame);
            this.onValueChange({ target: { value: defaultGame.name } });
        } else {
            this.props.changePanel({ ref: this, game: null });
        }
    }

    onValueChange(event) {
        var game = null;
        if (event != null) {
            game = gameList.find(t => t.name === event.target.value);
            this.setState({ game: game });
        }
        this.props.changePanel({ ref: this, game: game });
    }

    render() {
        return (
            <React.Fragment>
                <div className="game-area-header">
                    <p>GAME AREA {this.props.gameArea}</p>
                    <select className="game-select" value={this.state.game ? this.state.game.name : "Please Select"} onChange={this.onValueChange}>
                        <option className="game-select-option" value="Please Select" key="1">Please Select</option>
                        {gameList.map(t => (
                            <option className="game-select-option" key={t.name} value={t.name}>{t.name}</option>
                        ))}
                    </select>
                </div>
            </React.Fragment>
        );
    }
}
export default GameAreaHeader;