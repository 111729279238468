import React from "react";
import DicePredictionColumn from "./DicePredictionColumn";
import DiceSlider from "./DiceSlider";
import PayoutColumn from "../Game/PayoutColumn";
import "./dice.css"
import DiceGameLogo from "../../assets/images/dice-game-logo.png";
import InputNumber from 'rc-input-number'

import { placeBet } from "../../utils/WebApiUtils"

const MaxBetAmount = 1000000;

const delay = ms => new Promise(res => setTimeout(res, ms));

class DicePanel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      luckyNumber: 50,
      prediction: 51,
      rollUnder: true,
      betAmount: 20,
      disabled: ""
    }

    this.getBetButtonClass = this.getBetButtonClass.bind(this);
    this.setDicePanelState = this.setDicePanelState.bind(this);
    this.gameMultiplier = this.gameMultiplier.bind(this);
    this.chanceToWin = this.chanceToWin.bind(this);
    this.placeBet = this.placeBet.bind(this);
    this.betAmountChanged = this.betAmountChanged.bind(this);
    this.diceSliderElement = React.createRef();
    this.payoutColumnRef = React.createRef();
    this.dicePredictionColumn = React.createRef();
  }

  setDicePanelState(state) {
    this.setState(state);
  }

  gameMultiplier() {
    return this.state.rollUnder ? 98 / this.state.prediction : 98 / (99 - this.state.prediction);
  }

  chanceToWin() {
    return this.state.rollUnder ? this.state.prediction : 100 - this.state.prediction - 1;
  }

  componentDidUpdate() {
    this.diceSliderElement.current.setRange(this.state.rollUnder);
    this.payoutColumnRef.current.setValues();
  }

  async placeBet() {
    this.setState({
      disabled: "disabled"
    });
    try {
      if (!this.dicePredictionColumn.current.getInterval()) {
        placeBet("Dice");
        this.dicePredictionColumn.current.waitForResult();
        var result = await this.getResult();
        this.dicePredictionColumn.current.luckyNumberResult(result);
      }
    } catch (e) {
      this.dicePredictionColumn.current.luckyNumberResult(0);
      console.error(e);
    }
    this.setState({
      disabled: ""
    });
  }

  async getResult() {
    await delay(2500);
    var result = await fetch("/dice/random", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ betId: "Test" })
    });
    var data = await result.json();
    return data.value; //Dice result
  }

  betAmountChanged(res) {
    if (res < MaxBetAmount) {
      this.setState({ betAmount: res });
    }
  }

  getBetButtonClass() {
    var baseClasses = "total-col-inner total-col-inner3 dice-bet-button";
    var additionalClasses = this.state.disabled === "disabled" ? "dice-bet-button-disabled" : null;
    if(additionalClasses) {
      baseClasses = baseClasses + " " + additionalClasses;
    }
    return baseClasses;
  }

  render() {
    return (
      <React.Fragment>
        <DicePredictionColumn
          ref={this.dicePredictionColumn}
          slider={this.diceSliderElement}
          setDicePanelState={this.setDicePanelState}
          rollUnder={this.state.rollUnder}
          prediction={this.state.prediction}
          luckyNumber={this.state.luckyNumber}>
        </DicePredictionColumn>

        <DiceSlider
          ref={this.diceSliderElement}
          prediction={this.state.prediction}
          rollUnder={this.state.rollUnder}
          setDicePanelState={this.setDicePanelState}>
        </DiceSlider>

        <PayoutColumn
          betAmount={this.state.betAmount}
          ref={this.payoutColumnRef}
          prediction={this.state.prediction}
          rollUnder={this.state.rollUnder}
          multiplierCallback={this.gameMultiplier}
          chanceToWinCallback={this.chanceToWin}>
        </PayoutColumn>

        <div className="total-col total-col1">
          <div className="total-col-inner total-col-inner1">
            <img src={DiceGameLogo} alt="#" />
            <div className="bet-col">
              <InputNumber className="dice-bet-amount" step={0.1} precision={2} onChange={this.betAmountChanged} value={this.state.betAmount} />
              <p>Bet Amount</p>
            </div>
            <div className="x-xol hidden-xs">
              <div className="x-half">1/2</div>
              <div className="x-double">X2</div>
              <div className="max">MAX</div>
            </div>
          </div>
          <div className="total-col-inner total-col-inner2">
            <div className="switch switch--vertical">
              <input id="radio-c" type="radio" name="second-switch" onChange={() => { }} checked="checked" />
              <input id="radio-d" type="radio" name="second-switch" />
              <span className="toggle-outside"><span className="toggle-inside"></span></span>
            </div>
            <p>Auto</p>
          </div>
          <button disabled={this.state.disabled} onClick={async () => { await this.placeBet(); }} className={this.getBetButtonClass()}>BET {this.state.betAmount} TRX</button>
        </div>
        <div className="total-col total-col1 total-col1-xs visible-xs">
          <div className="total-col-inner total-col-inner1">
            <div className="x-xol">
              <div className="x-half">1/2</div>
              <div className="x-double">X2</div>
              <div className="max">MAX</div>
            </div>
          </div>
          <div className="total-col-inner total-col-inner2">
            <div className="switch switch--vertical">
              <input id="radio-c" type="radio" name="second-switch" onChange={() => { }} checked="checked" />
              <input id="radio-d" type="radio" name="second-switch" />
              <span className="toggle-outside"><span className="toggle-inside"></span></span>
            </div>
            <p>Auto</p>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default DicePanel;
