import React from "react";
import jackpotImage from '../../assets/images/jackpot-chest.png';

const Jackpot = () => (
    <div className="jackpot-col">
        <div className="jackpot-inner-col">
            <div>
                <i className="fa fa-question-circle-o"></i>
                <img src={jackpotImage} alt="#" />
            </div>
            <div>
                <p>JACKPOT <span>TRX</span><br/><b>1,253,395</b></p>
                <i className="fa fa-angle-right"></i>
            </div>
        </div>
        <div className="jackpot-inner-col">
            <p>MINI<br/><b>5%</b></p>
            <i className="fa fa-angle-right"></i>
        </div>
        <div className="jackpot-inner-col">
            <p>MAJOR<br/><b>20%</b></p>
            <i className="fa fa-angle-right"></i>
        </div>
        <div className="jackpot-inner-col">
            <p>MEGA<br/><b>80%</b></p>
        </div>
    </div>
);

export default Jackpot;
