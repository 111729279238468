import React from "react";

const MaximumRandomLuckyNumber = 99;
const MinimumRandomLuckyNumber = 0;
const RandomNumberIntervalMs = 300;

class DicePredictionColumn extends React.Component {

    constructor(props) {
        super(props);
        this.randomLuckyNumber = this.randomLuckyNumber.bind(this);
        this.luckyNumberResult = this.luckyNumberResult.bind(this);
        this.waitForResult = this.waitForResult.bind(this);
        this.rollInterval = null;
        this.getInterval = this.getInterval.bind(this);
        this.state = {
            hasRecievedResult: false,
            previousRollUnderState: false,
            previousLuckyNumber: 0,
            previousPrediction: 0,
            luckyNumber: this.props.luckyNumber
        }
    }

    changeRollUnderState(rollUnder) {
        this.props.setDicePanelState({ rollUnder: rollUnder });
    }

    waitForResult() {
        this.setState({
            hasRecievedResult: false,
            recvRollUnder: this.props.rollUnder,
            recvPrediction: this.props.prediction
        });
        this.rollInterval = setInterval(this.randomLuckyNumber, RandomNumberIntervalMs);
    }

    randomLuckyNumber() {
        this.setState({
            luckyNumber: Math.floor(Math.random() * MaximumRandomLuckyNumber) + MinimumRandomLuckyNumber
        })
    }

    getInterval() {
        return this.rollInterval;
    }

    luckyNumberResult(luckyNumber) {
        if (this.rollInterval) {
            clearInterval(this.rollInterval);
        }

        this.setState({
            luckyNumber: luckyNumber,
            hasRecievedResult: true,
            recvLuckyNumber: luckyNumber
        });
        this.rollInterval = null;
    }

    hasWon() {
        if (this.state.hasRecievedResult === false) {
            return true;
        }
        return this.state.previousRollUnderState ? this.state.previousLuckyNumber < this.state.previousPrediction : this.state.previousLuckyNumber > this.state.previousPrediction;
    }

    render() {
        return (
            <div className="prediction-col">
                <div className="prediction-col-inner prediction-col-inner1">
                    <h1 className="prediction-number"><span>{this.props.prediction}</span><br />Prediction</h1>
                    <button onClick={() => this.changeRollUnderState(true)} type="button" className={`roll-under ${this.props.rollUnder ? "active" : ""}`}>ROLL UNDER</button>
                </div>
                <div className="prediction-col-inner prediction-col-inner2">
                    <h1 className="lucky-number"><span id="luckyNumber" className={this.hasWon() ? "" : "dice-losing-result-span"}>{this.state.luckyNumber}</span><br />Lucky Number</h1>
                    <button onClick={() => this.changeRollUnderState(false)} type="button" className={`roll-over ${!this.props.rollUnder ? "active" : ""}`}>ROLL OVER</button>
                </div>
            </div>);
    }
}

export default DicePredictionColumn;