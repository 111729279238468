import React from "react";
import Carousel from 'react-bootstrap/Carousel';
import { Row, Col, Container } from "reactstrap";
// import logo from "../assets/logo.svg";

const Hero = () => (
    <div className="hero-sec hidden-xs">
        <Container>
<Row>
    <Col className="col-12">
        <Carousel slide={false} controls={false}>
            <Carousel.Item>
            <a href="/dashboard" >
                <div className="center slider">
                    <div className="carousel-item-row">
                        <div className="carousel-item-left">
                        <h1>Lucky Dice</h1>
                        <h2>Up to  <span>1 000 TRX</span></h2>
                        <p>Check our new game!<br/>&nbsp;</p>
                        </div>
                        <div className="carousel-item-right">
                        <img src="/img/game1.png" alt="#" className="d-block" />
                        </div>
                    </div>
                </div>
                {/* <Carousel.Caption>
                    <h2>NEW GAME</h2>
                </Carousel.Caption> */}
            </a>
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-item-row">
                <div className="carousel-item-left">
                    <h1>DAILY TASKS</h1>
                    <h2>Up to  <span>1 000 TRX</span></h2>
                    <p>BONUS for completing todays <br/>daily tasks !</p>
                </div>
                <div className="carousel-item-right">
                    <img src="/img/game2.png" alt="#" className="d-block" />
                </div>
                </div>
                {/* <Carousel.Caption>
                    <h1>Risk Duel</h1>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
                <div className="carousel-item-row">
                <div className="carousel-item-left">
                    <h1>DAILY TASKS</h1>
                    <h2>Up to  <span>1 000 TRX</span></h2>
                    <p>BONUS for completing todays <br/>daily tasks !</p>
                </div>
                <div className="carousel-item-right">
                <img src="/img/game3.png" alt="#" className="d-block" />
                </div>
                </div>
                {/* <Carousel.Caption>
                    <h1>Fuse</h1>
                </Carousel.Caption> */}
            </Carousel.Item>
        </Carousel>
    </Col>
</Row>
</Container>
</div>
);

export default Hero;