import React, { Component } from "react";
import Loading from "./Loading";
import { Row, Col, Container } from "reactstrap";
import { getGames } from "../utils/WebApiUtils"

class ContentSec extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      isFetching: true,
      games: []
    };
  }

  componentDidMount() {
    getGames().then((result) => {
      this.setState({
        isFetching: false,
        games: result
      });
    });
  }

  render() {
    const { isFetching, games } = this.state;
    
    if (isFetching) {
      return <Loading />;
    }

    return (
      <div className="feature-sec">
	<div className="container">
		<div className="row">
			<div className="col-12">
				<div className="center1 slider">
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/crypto.png" alt="#" />
							<h6>Crypto Bonus Payouts</h6>
							<p>Betfiity profits are shared between all holders <br/>of our exclusive HOUSE Tokens. HOUSE Tokens <br/>offer daily passive revenue-generation from our <br/>Crypto-Bonus pool.</p>
						</div>
						<div className="feature-block">
							<img src="/img/bonus.png" alt="#" />
							<h6>Bonus Task Rewards</h6>
							<p>Tasks make gameplay extra rewarding. <br/>Earn bonuses for completing Daily &amp; weekly <br/>tasks and activities.</p>
						</div>
						<div className="feature-block">
							<img src="/img/player.png" alt="#" />
							<h6>Player Ranking System</h6>
							<p>A comprehensive ranking system offers players <br/>additional features and benefits. Increased <br/>dividends, exclusive invitations and more await <br/>those as they rise to the top.</p>
						</div>
					</div>
				</div>
			</div>
			<div className="col-12">
				<div className="center2 slider">
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
					<div>
						<div className="feature-block">
							<img src="/img/referral.png" alt="#" />
							<h6>Referral Bonus System</h6>
							<p>Betfinity features an invitation referral system. <br/>Players will recieve an ongoing share of their <br/>referrals winnings and mined HOUSE Tokens.</p>
						</div>
						<div className="feature-block">
							<img src="/img/jackpots.png" alt="#" />
							<h6>Jackpots</h6>
							<p>Betfinity features a Jackpot pool that grows <br/>with gameplay. Mini and Mega Jackpots can <br/>be won by playing any game or betting system.</p>
						</div>
						<div className="feature-block">
							<img src="/img/guild.png" alt="#" />
							<h6>Guild System</h6>
							<p>Our exclusive Guild System gives players the <br/>ability to create a team that can work together <br/>to achieve tasks and compete in challenges <br/>giving team-wide rewards and benefits.</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
    )};
  }

export default ContentSec;
