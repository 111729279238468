import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logoImage from '../assets/images/logo.png';

import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

import { useAuth0 } from "../react-auth0-spa";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin
    });

  return (
    <Navbar light expand="md">
      <Container>
        <NavbarBrand className="navbarBrand" href="/" >
          <img src={logoImage} style={{ width: "6em" }} alt="brand logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} className="navbar-dark" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="navbar-nav" navbar>
            {/* <NavItem>
              <NavLink
                className="nav-item"
                tag={RouterNavLink}
                to="/promotions"
                exact
                activeClassName="active"
              >
                Promotions
                  </NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink
                className="nav-item"
                tag={RouterNavLink}
                to="/"
                exact
                activeClassName="active"
              >
                Games
                </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                target="blank"
                download
                className="nav-item"
                tag={RouterNavLink}
                to="/download/files/Whitepaper.pdf"
                exact
                activeClassName="active"
              >
                Whitepaper
                </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className="nav-item"
                tag={RouterNavLink}
                to="/faq"
                exact
                activeClassName="active"
              >
                Faqs
                </NavLink>
            </NavItem> */}
          </Nav>
          {/* <Nav className="header-login-col ml-auto" navbar>
            {!isAuthenticated && (
              <NavItem>
                <Button
                  id="qsLoginBtn"
                  onClick={() => loginWithRedirect({})}
                  className=""
                >
                  Login
                  </Button>
              </NavItem>
            )}
            {isAuthenticated && (
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret id="profileDropDown">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile rounded-circle"
                    width="50"
                  />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem header>{user.name}</DropdownItem>
                  <DropdownItem
                    tag={RouterNavLink}
                    to="/profile"
                    className="dropdown-profile"
                    activeClassName="router-link-exact-active"
                  >
                    <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                  <DropdownItem
                    id="qsLogoutBtn"
                    onClick={() => logoutWithRedirect()}
                  >
                    <FontAwesomeIcon icon="power-off" className="mr-3" /> Log
                      out
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            )}
          </Nav> */}
          {/* {!isAuthenticated && (
            <Nav className="d-md-none" navbar>
              <NavItem>
                <Button
                  id="qsLoginBtn"
                  color="primary"
                  block
                  onClick={() => loginWithRedirect({})}
                >
                  Log in
                  </Button>
              </NavItem>
            </Nav>
          )}
          {isAuthenticated && (
            <Nav
              className="d-md-none justify-content-between"
              navbar
              style={{ minHeight: 170 }}
            >
              <NavItem>
                <span className="user-info">
                  <img
                    src={user.picture}
                    alt="Profile"
                    className="nav-user-profile d-inline-block rounded-circle mr-3"
                    width="50"
                  />
                  <h6 className="d-inline-block">{user.name}</h6>
                </span>
              </NavItem>
              <NavItem>
                <FontAwesomeIcon icon="user" className="mr-3" />
                <RouterNavLink
                  to="/profile"
                  activeClassName="router-link-exact-active"
                >
                  Profile
                  </RouterNavLink>
              </NavItem>
              <NavItem>
                <FontAwesomeIcon icon="power-off" className="mr-3" />
                <RouterNavLink
                  to="#"
                  id="qsLogoutBtn"
                  onClick={() => logoutWithRedirect()}
                >
                  Log out
                  </RouterNavLink>
              </NavItem>
            </Nav>
          )} */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
