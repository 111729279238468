import React from "react"
import CompetitorList from "./CompetitorList"
import PoolInformation from "./PoolInformation";
import PayoutColumn from "../Game/PayoutColumn";
import RiskTotal from "./RiskTotal";
import "./riskduel.css"

class RiskDuelPanel extends React.Component {

    constructor(props) {
        super(props);
        this.riskPlayers = [];
        this.state = {
            riskPlayers: this.riskPlayers,
            countdown: 30,
            maximumPool: 200000,
            buyInAmount: 20000,
            betAmount: 10000,
            totalBuyIn: 1,
            winChance: 1,
        };
        this.updateState = this.updateState.bind(this);
        this.gameMultiplier = this.gameMultiplier.bind(this);
        this.chanceToWin = this.chanceToWin.bind(this);
        this.setWinChance = this.setWinChance.bind(this);
        this.payoutColumnRef = React.createRef();
        this.poolInformation = React.createRef();
    }

    gameMultiplier() {
        // TODO
        return 8;
    }

    chanceToWin() {
        return this.state.winChance;
    }

    setWinChance() {
        var totalBets = 0;
        this.state.riskPlayers.forEach(t => totalBets += t.betAmount);
        totalBets += this.state.betAmount;
        var winChance = 100 / totalBets * this.state.betAmount;
        this.updateState({
            winChance: winChance
        });
    }

    updateState(state) {
        this.setState(state);
    }

    componentDidUpdate() {
        this.payoutColumnRef.current.setValues();
    }

    componentDidMount() {
        this.addPlayer({
            name: "Test",
            color: "#52D726",
            betAmount: 2250
        });
        this.addPlayer({
            name: "Test2",
            color: "#FFEC00",
            betAmount: 1350
        });
        this.addPlayer({
            name: "Test4",
            color: "#FF7300",
            betAmount: 6000
        });

        this.addPlayer({
            name: "Test5",
            color: "#FF0000",
            betAmount: 2000
        });

        this.addPlayer({
            name: "Test6",
            color: "#007ED6",
            betAmount: 3000
        });

        this.addPlayer({
            name: "Test7",
            color: "#7CDDDD",
            betAmount: 2000
        });
        this.interval = setInterval(this.countdownTimer.bind(this), 1000);
        this.setWinChance();
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    stopTimer() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    countdownTimer() {
        if (this.state.countdown > 0) {
            this.setState({
                countdown: this.state.countdown - 1
            });
        } else {
            this.stopTimer();
        }
    }

    addPlayer(riskPlayer) {
        this.riskPlayers.push(riskPlayer);
        this.updatePlayerList();
    }

    removePlayer(playerName) {
        this.riskPlayers.splice(this.riskPlayers.filter(t => t.name === playerName));
        this.updatePlayerList();
    }

    updatePlayerList() {
        this.updateState({ riskPlayers: this.riskPlayers });
        this.setWinChance();
    }

    render() {
        return (
            <React.Fragment>
                <div className="competitors-col">
                    <CompetitorList playerList={this.state.riskPlayers} />

                    <PoolInformation
                        ref={this.poolInformation}
                        playerList={this.state.riskPlayers}
                        countdown={this.state.countdown}
                        buyInAmount={this.state.buyInAmount}
                        maximumPool={this.state.maximumPool} />
                </div>

                <PayoutColumn
                    betAmount={this.state.betAmount}
                    ref={this.payoutColumnRef}
                    multiplierCallback={this.gameMultiplier}
                    chanceToWinCallback={this.chanceToWin}>
                </PayoutColumn>
                <RiskTotal betAmount={this.state.betAmount} totalBuyIn={this.state.totalBuyIn} />
            </React.Fragment>
        )
    }
}

export default RiskDuelPanel;