import React from "react";

class RiskTotal extends React.Component {
    render() {
        return (
            <div className="total-col">
                <div className="total-col-inner total-col-inner1">
                    <p>{this.props.betAmount} TRX<br /><span>TOTAL BET</span></p>
                </div>
                <div className="total-col-inner total-col-inner2">
                    <p>{this.props.totalBuyIn}<br /><span>TOTAL BUY INS</span></p>
                </div>
                <div className="total-col-inner total-col-inner3">
                    <p>BUY IN {this.props.betAmount} TRX<br />- 2 REMAIN -</p>
                </div>
            </div>
        );
    }
}

export default RiskTotal;