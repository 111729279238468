import React from "react";
import InputRange from 'react-input-range';

class DiceSlider extends React.Component {
    handlePredictionChange(event) {
        this.setState({ prediction: event });
        this.props.setDicePanelState({ prediction: event })
    }

    constructor(props) {
        super(props);
        this.state = {
            minValue: 4,
            maxValue: 98,
            prediction: props.prediction
        };
    }

    componentDidMount = () => {
        this.setRange(this.props.rollUnder);
    }

    setRange(rollUnder) {
        var minValue = -1;
        var maxValue = -1;
        var prevValue = this.state.prediction;
        if (rollUnder) {
            minValue = 1;
            maxValue = 95;
        } else {
            minValue = 4;
            maxValue = 98;
        }

        var value = Math.max(this.state.prediction, minValue);
        value = Math.min(value, maxValue);
        if (prevValue !== value) {
            this.props.setDicePanelState({ prediction: value, minValue: minValue, maxValue: maxValue });
        }

        this.setState({ prediction: value, minValue: minValue, maxValue: maxValue });

    }

    render() {
        return (
            <InputRange minValue={this.state.minValue} maxValue={this.state.maxValue} value={this.state.prediction} onChange={this.handlePredictionChange.bind(this)}></InputRange>
        );
    }
}

export default DiceSlider;