import React from "react";

let maxPlayerSlots = 10;

class CompetitorList extends React.Component {

    getWinChance(player) {       
        var totalBets = 0;
        this.props.playerList.forEach(t => totalBets += t.betAmount);
        var winChance = 100 / totalBets * player.betAmount;
        return winChance.toFixed(winChance % 1 && 2);
    }

    render() {
        var playerView = this.props.playerList.map(t => (
            <li style={{color: t.color}} key={t.name}>
                <p>{t.name} <span>{this.getWinChance(t)}%</span></p>
            </li>
        ));
        var emptySlots = maxPlayerSlots - this.props.playerList.length;
        var empty = 0;
        var emptySlotsView = Array.apply(null, {length: emptySlots}).map(t => (
            <li className="empty" key={empty++}>
            </li>
        ));

        return (
            <div className="competitors--inner">
                <h1>C O M P E T I T O R S <span>CHANCE<br />TO WIN</span></h1>
                <ul>
                    {playerView}
                    {emptySlotsView}
                </ul>
            </div>
        );
    }
}

export default CompetitorList;