import React from "react";
import { Doughnut } from 'react-chartjs-2';

class PoolInformation extends React.Component {

    constructor(props) {
        super(props);
        this.chartReference = React.createRef();
        this.getChartData = this.getChartData.bind(this);
    }

    getChartData() {
        var data = {
            labels: [],
            datasets: [{
                label: 'Risk Duel Panel',
                backgroundColor: [],
                hoverBackgroundColor: [],
                borderColor: [],
                data: []
            }]
        };
        for (var i = 0; i < this.props.playerList.length; i++) {
            var player = this.props.playerList[i];
            data.labels.push(player.name);
            data.datasets[0].data.push(player.betAmount);
            data.datasets[0].backgroundColor.push(player.color);
            data.datasets[0].hoverBackgroundColor.push(player.color);
            data.datasets[0].borderColor.push(player.color);
        }
        return data;
    }

    getChartOptions() {
        return {
            cutoutPercentage: 97,
            legend: {
                display: false
            }
        };
    }

    render() {
        var totalBets = 0;
        this.props.playerList.forEach(t => totalBets += t.betAmount);
        return (
            <div className="competitors--inner1">
                <div className="competitors-chart-col">
                    <Doughnut ref={this.chartReference} data={this.getChartData()} options={this.getChartOptions()} id="myChart"></Doughnut>
                    <div className="competitors-chart-col-text">
                        <p>T O T A L   P O O L</p>
                        <h4>{totalBets} TRX</h4>
                        <hr />
                        <p>C O U N T D O W N</p>
                        <h1>{this.props.countdown}</h1>
                    </div>
                </div>
                <div className="pool-col">
                    <p>{this.props.maximumPool}<br /><span>MAXIMUM POOL</span></p>
                    <p>{this.props.buyInAmount}<br /><span>BUY IN</span></p>
                </div>
            </div>
        );
    }
}

export default PoolInformation;