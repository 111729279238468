import React from "react";
import GameAreaHeader from "../components/Game/GameAreaHeader";
import Jackpot from "../components/Game/Jackpot";
import * as Games from '../components/Game/Games';
import { Container } from "reactstrap";
const maxPanels = 2;

class GameDashboard extends React.Component {

    constructor(props) {
        super(props);
        this.panels = [];
        this.state = {
            panels: this.panels
        };
        this.getGame = this.getGame.bind(this);
        this.changePanel = this.changePanel.bind(this);
    }

    componentDidMount() {
        this.addPanel(Games.DiceGameName);
        this.addPanel(Games.RiskDuelName);
    }

    addPanel(defaultGame) {
        if (this.state.panels.length < maxPanels) {
            this.panels.push({
                ref: React.createRef(),
                game: null,
                gameArea: this.panels.length + 1,
                defaultGame: defaultGame
            });
            this.setState({ panels: this.panels });
        }
    }

    getGame(game) {
        if (game) {
            return game;
        }
        return (<p>No Game Selected</p>);
    }

    changePanel(gameDetails) {
        var game = this.getGame(gameDetails.game);
        var panel = this.panels.find(t => t.gameArea === gameDetails.ref.props.gameArea);
        if (game.onCreateGame) {
            panel.game = game.onCreateGame(panel.gameArea);
        } else {
            panel.game = game;
        }
        this.setState({ panels: this.panels });
    }

    render() {
        return (
            <div className="dashboard-sec">
                <Container fluid="lg">
                    <div className="row">
                        {this.state.panels.map(t => (
                            <div key={t.gameArea} className="col-12 col-md-6 game-area-col">
                                <GameAreaHeader defaultGame={t.defaultGame} changePanel={this.changePanel} ref={t.ref} gameArea={t.gameArea} />
                                <Jackpot />
                                {t.game}
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
        );
    }
}
export default GameDashboard;