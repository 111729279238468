import React, { Component } from "react";
import GamePanel from "./GamesPanel";

class Content extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <GamePanel />
      </div>
    )};
  }

export default Content;
