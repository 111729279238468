import React, { Fragment } from "react";
import { Container } from "reactstrap";

import Hero from "../components/Hero";
import Content from "../components/Content";

const Home = () => (
  <div>
    <Hero />
    <Content />
  <Fragment>
    
    
  </Fragment>
  <Container>
        <div className="row">
          <div className="col-12 col-md-2 hidden-xs">
              <img className="logo-icon" src="/img/logo-icon.png" alt="#" />
          </div>
          <div className="col-12 col-md-10">
              <h3><img className="logo-icon visible-xs" src="/img/logo-icon.png" alt="#" /> BETFINITY, A NEW TYPE OF GAMING ON TRON!</h3>
              <p>Want more from your betting and gaming? Betfinity is a new Smart Contract controlled platform powered by the Tron Network. Creating a unique mix of traditional gambling and modern gaming, players can enjoy our range of gaming systems whilst they earn and win. Providing a safe secure gaming space coupled with unique promotions and features <span>Betfinity gives players more than ever before.</span> </p>
          </div>
        </div>
      </Container>
  </div>
);

export default Home;
