import React from "react";

class PayoutColumn extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            totalPayout: 1,
            multiplier: 1,
            chanceToWin: 1
        }
    }

    setValues() {
        var multiplier = this.props.multiplierCallback();
        var winAmount = this.props.betAmount * multiplier;
        var chanceToWin = this.props.chanceToWinCallback();
        var totalPayout = multiplier * this.props.betAmount;
        this.setState({
            multiplier: multiplier,
            winAmount: winAmount,
            chanceToWin: chanceToWin,
            totalPayout: totalPayout
        });
    }

    componentDidMount() {
        this.setValues();
    }

    render() {
        return (
        <div className="payout-col">
            <ul>
                <li>
                    <p>{this.state.totalPayout.toFixed(this.state.totalPayout % 1 && 4)}<br /><span>Total Payout</span></p>
                </li>
                <li>
                    <p><b>x</b> {this.state.multiplier.toFixed(this.state.multiplier % 1 && 4)}<br /><span>Multiplier</span></p>
                </li>
                <li>
                    <p>{this.state.chanceToWin.toFixed(this.state.chanceToWin % 1 && 4)}%<br /><span>Chance to Win</span></p>
                </li>
            </ul>
        </div>
        );
    }
}

export default PayoutColumn;