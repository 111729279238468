import React from "react";
import twitterImage from '../assets/images/twitter.png';
import tImage from '../assets/images/t.png';
import mImage from '../assets/images/m.png';
import fImage from '../assets/images/f.png';
import yImage from '../assets/images/y.png';
import iImage from '../assets/images/i.png';
import lImage from '../assets/images/l.png';
import globeImage from '../assets/images/globe.png';
import footerLogoImage from '../assets/images/footer-logo.png';
import envelopeImage from '../assets/images/envelope.png';
import plus1Image from '../assets/images/plus1.png';


const Footer = () => (
    <footer className="footer">
        <div className="container">
            <div className="row">
                <div className="col-12 col-md-9 footer-menu-col">
                    <h5>Betfinity Social Links</h5>
                    <ul>
                        <li>
                            <a href="https://twitter.com/BetfinityGaming"><img src={twitterImage} alt="Twitter Logo" /></a>
                        </li>
                        <li>
                            <a href="https://t.me/betfinity"><img src={tImage} alt="Telegram Logo" /></a>
                        </li>
                        <li>
                            <a href="https://medium.com/@betfinityio"><img src={mImage} alt="Medium Logo" /></a>
                        </li>
                        <li>
                            <a href="https://www.facebook.com/betfinitygaming"><img src={fImage} alt="Facebook Logo" /></a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCKm73I0I2uNYHjBZVHS2t7A"><img src={yImage} alt="YouTube Logo" /></a>
                        </li>
                        <br />
                        <li>
                            <a href="https://www.instagram.com/betfinity/"><img src={iImage} alt="Instagram Logo" /></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/51639444"><img src={lImage} alt="LinkedIn Logo" /></a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-md-3 footer-logo-col">
                    <div className="footer-logo-col-left">
                        <img src={footerLogoImage} align="#" alt="footer logo" />
                    </div>
                    <div className="footer-logo-col-right">
                        <h5>Contact Us</h5>
                        <a href="/"><h6><img src={globeImage} alt="betfinity.io" />betfinity.io</h6></a>
                        <a href="mailto:team@betfinity.io"><h6><img src={envelopeImage} alt="email betfinity team" /> team@betfinity.io</h6></a>
                        <h5 className="visible-xs">Resources</h5>
                        <a href="/assets/files/Whitepaper.pdf" className="visible-xs">Betfinity Whiteaper</a>
                    </div>
                </div>
                <div className="col-12 hidden-xs">
                    <hr />
                </div>
                <div className="col-12 col-md-9 plus-col">
                    <p><img src={plus1Image} alt="#" />
                        <span>If you reside in a location where lottery, gambling, or betting over the internet is illegal, please do not click on anything related to these activities on this site. You must be 18 years of age to click on any gambling related items even if it is legal to do so in your location.
                    <br />
                            <b>Recognising that the laws and regulations involving online gaming are different everywhere, players are advised to check with the laws that exist within their own jurisdiction or region to ascertain the legality of the activities which are covered.</b></span></p>
                    <p>The games provided by Betfinity are based on blockchain, fairness and transparency. When you start playing these games, please take note that online gambling and lottery is an entertainment vehicle and that it carries with it a certain degree of financial risk. Players should be aware of these risks and govern themselves accordingly.</p>
                </div>
                <div className="col-12 col-md-3 resources-col hidden-xs">
                    <h5>Developer Resources</h5>
                    <ul>
                        <li>
                            <div title="COMING SOON!" >
                                <a href="/">Resources</a>
                            </div>
                        </li>
                        <li><a href="/download/files/Whitepaper.pdf">Betfinity Whitepaper</a></li>
                        <li>
                            <div title="COMING SOON!">
                                <a href="/">Development Packages</a>
                            </div>
                        </li>
                        <li>
                            <a href="https://github.com/MODDS-Betting/">Github</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;
